import React from 'react'
import starIcon from '../../assets/images/star-icon.png'
import { StaticImage } from "gatsby-plugin-image"

const OurFeatures = () => {
    return (
        <section className="services-area pt-100 pb-70 bg-f1f8fb">
            <div className="container">
                <div className="section-title">
                    <span className="sub-title">
                        <img src={starIcon} alt="feature" /> 
                        Our Features
                    </span>

                    <h2>We’re Here To Help</h2>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <StaticImage src="../../assets/images/corporate/courses.jpg" width={400} height={300} placeholder="blurred" alt="Courses" />
                            </div>
                            <h3>Courses</h3>
                            <p></p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <StaticImage src="../../assets/images/corporate/eternal-connection-groups.jpg" width={400} height={300} placeholder="blurred" alt="Eternal Connection Group" />
                            </div>
                            <h3>Eternal Connection Groups</h3>
                            <p></p>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <StaticImage src="../../assets/images/corporate/meditation.jpg" width={400} height={300} placeholder="blurred" alt="Meditation" />
                            </div>
                            <h3>Meditation</h3>
                            <p></p>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <StaticImage src="../../assets/images/corporate/mindfulness.jpg" width={400} height={300} placeholder="blurred" alt="Mindfulness" />
                            </div>
                            <h3>Mindfulness</h3>
                            <p></p>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <StaticImage src="../../assets/images/corporate/relax.jpg" width={400} height={300} placeholder="blurred" alt="Courses" />
                            </div>
                            <h3></h3>
                            <p></p>
                        </div>
                    </div>
                    
                    <div className="col-lg-4 col-sm-6">
                        <div className="single-services-item-box">
                            <div className="icon">
                                <StaticImage src="../../assets/images/corporate/wellbeing-family.jpg" width={400} height={300} placeholder="blurred" alt="Family Wellbeing" />
                            </div>
                            <h3>Family Wellbeing</h3>
                            <p></p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OurFeatures