import React from "react"
import {useStaticQuery, graphql} from 'gatsby'
import starIcon from "../../assets/images/star-icon.png"
import VideoResponsivePlayer from "../App/VideoResponsivePlayer"

const WhyChooseUs = () => {

  const data = useStaticQuery(graphql`
  query {
    site {
      siteMetadata {
        videosBaseUrl
      }
    }
  }
`)

  return (
    <section className="how-its-work-area ptb-100">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="how-its-work-content">
              <span className="sub-title">
                <img src={starIcon} alt="choose" />
                Why Choose Us
              </span>
              <h2>How We are Different </h2>
              <p>
              We have a vision of the world transformed through diversity connection: a future where everyone learns and helps each other grow, connect, and change the world together in a better place for them and the next generation.
              </p>

              <div className="inner-box">
                <div className="single-item">
                  <div className="count-box">1</div>
                  <h3>Energy Focus</h3>
                  <p>
                    We work in holistic wellness. That’s it. And that’s how we became
                    experts in the field.
                  </p>
                </div>
                <div className="single-item">
                  <div className="count-box">2</div>
                  <h3>Community Engagement</h3>
                  <p>
                  We partner with teachers, psychologists, therapists, nutritionists, physiotherapists, parents so that everyone has a hand in forging new opportunities for all.
                  </p>
                </div>
                <div className="single-item">
                  <div className="count-box">3</div>
                  <h3>Attacking the Root</h3>
                  <p>
                  We target the root causes by giving high-quality, tailored training, courses, and opportunities to develop our full potential.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="how-its-work-image">
            <VideoResponsivePlayer
                  url={data.site.siteMetadata.videosBaseUrl + '/corporate-wellbeing.mp4'}
                  controls={false}
                  playing={true}
                  loop={true}
                  muted={true}
                  width="100%"
                  height="100%"
                  ratio={1}
                />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WhyChooseUs
