import React from "react"
import Layout from "../components/App/Layout"
import LoveDiversity from '../components/Corporate/LoveDiversity'
import OurFeatures from '../components/Corporate/OurFeatures'
import WhyChooseUs from '../components/Corporate/WhyChooseUs'
import ContactInfo from '../components/Contact/ContactInfo'
import ContactForm from '../components/Contact/ContactForm'
import PageBannerBackground from '../components/Common/PageBannerBackground' 
import CorporateOverview from '../components/Corporate/CorporateOverview'

const Wellness = ({location}) => {
  
  location.state = { page: "corporate" }
  
  return (
    <Layout page="Wellness">
      <PageBannerBackground
                pageTitle="WELLNESS" 
                crumbs={[{page: "Home", url: "/"}, {page: "Corporate"}]}      
                bgFileName="corporate/corporate-banner.jpg" 
            />
      <CorporateOverview />
      <WhyChooseUs />
      <OurFeatures />
      <ContactInfo />
      <ContactForm location={location} />
      <LoveDiversity />
    </Layout>
  )
}

export default Wellness
