import React from "react"
import BackgroundImage from "gatsby-background-image"
import { graphql, useStaticQuery } from "gatsby"

const LoveDiversity = () => {
  const data = useStaticQuery(
    graphql`
      query {
        desktop: file(relativePath: { eq: "corporate/we-love-diversity.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `
  )

  // Set ImageData.
  const imageData = data.desktop.childImageSharp.fluid

  return (
    <section className="testimonials-area  pt-100 pb-70">
      <BackgroundImage
        Tag="section"
        fluid={imageData}
        className="masthead"
        backgroundColor={`#040e18`}
      >
        <div className="black-overlay">
          <div className="container pt-100 pb-70">
            <div className="section-title">
              <h2>We love diversity!</h2>
              <br/><br/>
              <div className="diversity-box">
                    
                <p>
                Cultural diversity is a broad term that encompasses, among other
                things, the various ethnic origins, languages, religions, and
                beliefs in a society. <br/>
                Celebrating diversity allows us to recognise our similarities as well as our differences. Research
                has shown that diversity facilitates creativity, collaboration,
                personal development, economic growth, and better
                decision-making.
                </p>
              </div>
            </div>
          </div>
        </div>
      </BackgroundImage>
    </section>
  )
}

export default LoveDiversity
