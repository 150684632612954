import React from "react"
import {useStaticQuery, graphql} from 'gatsby'
import starIcon from "../../assets/images/star-icon.png"
import VideoResponsivePlayer from "../App/VideoResponsivePlayer"

const CorporateOverview = () => {

  const data = useStaticQuery(graphql`
  query {
    site {
      siteMetadata {
        videosBaseUrl
      }
    }
  }
`)

  return (
    <section className="about-area bg-f1f8fb pb-100 pt-100">
      <div className="container">
        <div className="section-title">
          <span className="sub-title">
            <img src={starIcon} alt="feature" />
            Wellness courses and sessions
          </span>

          <h2>
            We specialise in bespoke wellness programs proven to boost morale,
            improve health and make your team feel good.
          </h2>
          <p>
            Keep your team connected and boost morale.
            Reduce stress and improve mental health.
            <br />
            Increase productivity and creativity.
            Improve the mood!.
          </p>
        </div>
        <div className="row align-items-center">
          <div className="col-lg-6 col-md-12">
            <div className="about-img">
              <VideoResponsivePlayer
                url={data.site.siteMetadata.videosBaseUrl + '/corporate-reiki.mp4'}
                controls={false}
                playing={true}
                loop={true}
                muted={true}
                width="100%"
                height="100%"
                ratio={1}
              />
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="about-content">
              <div className="content">
                <ul className="services-list mb-0">
                  <li>
                    <i className="flaticon-tick"></i>
                    Tailored to your team!
                  </li>
                  <li>
                    <i className="flaticon-tick"></i>
                    Virtual sessions for remote teams.
                  </li>
                  <li>
                    <i className="flaticon-tick"></i>
                    Fully bespoke sessions and workshops.
                  </li>
                  <li>
                    <i className="flaticon-tick"></i>
                    Programs that evolve and adapt with team needs.
                  </li>
                  <li>
                    <i className="flaticon-tick"></i>
                    Health and safety checks built in throughout.
                  </li>
                  <li>
                    <i className="flaticon-tick"></i>
                    Interactive sessions, no matter the team size.
                  </li>
                  <li>
                    <i className="flaticon-tick"></i>
                    Sessions can take place on a chair or mat and are suitable
                    for all.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default CorporateOverview
